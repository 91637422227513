// Ensures that error tracking is setup before any code or side effects
// that may be executed by dependencies
import './errors/tracking/init.client';

import { startTransition, StrictMode } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { HydratedRouter } from 'react-router/dom';

prepareApp().then(() => {
  startTransition(() => {
    hydrateRoot(
      document,
      <StrictMode>
        <HydratedRouter />
      </StrictMode>,
    );
  });
});

async function prepareApp() {
  if (import.meta.env.VITE_ENABLE_API_MOCKS === 'true') {
    const { worker, setupMSWGlobals, onUnhandledRequest } = await import('../mocks/browser.client');
    setupMSWGlobals();
    return worker.start({ onUnhandledRequest });
  }

  return Promise.resolve();
}
