import cx from 'classnames';
import type { ToastOptions } from 'react-toastify';
import { toast as toastToastify } from 'react-toastify';

import { Icon } from '~/components/Icon';
import { Link } from '~/components/Link';

export interface CompleteToast {
  message: string;
  description?: string;
  action?: {
    type: 'link';
    to: string;
    name: string;
  };
  position?: 'top-right' | 'bottom-center';
}

export type ToastContent = CompleteToast | string;

export function useToast() {
  return { toast };
}

const showToast = (toast: ToastContent, options: Pick<ToastOptions, 'icon' | 'type'> = {}) => {
  const {
    message,
    description,
    action,
    position = 'top-right',
  } = typeof toast === 'string' ? { message: toast } : toast;

  toastToastify(
    <div
      className={cx('flex', {
        'flex-col': position === 'top-right',
        'flex-row gap-x-1 items-center': position === 'bottom-center',
      })}
    >
      <span
        className={cx('text-black', {
          'text-s-regular': !description,
          'text-s-semibold': description,
        })}
      >
        {message}
      </span>
      {description ? (
        <span
          className={cx('text-s-regular text-warmgray-900', {
            'mt-1': position === 'top-right',
          })}
        >
          {description}
        </span>
      ) : null}
      {action ? (
        <Link
          to={action.to}
          className={cx('capitalize underline', {
            'mt-2': position === 'top-right',
            'mb-0.5': position === 'bottom-center',
          })}
          variant="tertiary"
          color="blue"
        >
          {action.name}
        </Link>
      ) : null}
    </div>,
    {
      hideProgressBar: true,
      position: position,
      style: {
        borderRadius: '12px',
        boxShadow: `0px 4px 4px -10px rgba(0, 0, 0, 0.6), inset 0px -1px 0px rgba(0, 0, 0, 0.14), inset 0px 2px 0px rgba(255, 255, 255, 0.25)`,
        padding: position === 'top-right' ? '16px' : '12px',
        width: position === 'top-right' ? '400px' : undefined,
      },
      closeButton: <Icon name="Close" className="ml-2 text-xs text-warmgray-600" />,
      className: cx('border border-warmgray-100', {
        'items-center justify-center flex': position === 'bottom-center',
      }),
      ...options,
    },
  );
};

const toast = {
  success: (content: ToastContent) => {
    showToast(content, {
      type: 'success',
      icon: <Icon name="CheckCircle" className="text-warmgreen-500" />,
    });
  },
  error: (content: ToastContent) => {
    showToast(content, {
      type: 'error',
      icon: <Icon name="Warning" className="text-state-danger" />,
    });
  },
  info: (content: ToastContent) => {
    showToast(content, {
      type: 'info',
      icon: <Icon name="Info" className="text-brand-blue" />,
    });
  },
  warning: (content: ToastContent) => {
    showToast(content, {
      type: 'warning',
      icon: <Icon name="warning_circle" className="text-state-warning" />,
    });
  },
};
